<template>
  <div class="container">
<!--    <div>我的邀请</div>-->
    <div class="rule" style="display: flex;justify-content: space-between">
      <div @click="toMyInvite">我的邀请</div>
      <div @click="toInviteRule()">活动规则</div>
    </div>
    <div class="title1">邀请好友租房 共赢现金奖励</div>
    <div class="title2">400元现金奖励等你来拿</div>
    <van-image
      class="image"
      src="https://img1.clozhome.com/new-clozhome/app/invite_banner.png"
      fit="cover"
    />
    <button class="invite-button" @click="toInviteFriend()">邀请好友</button>
    <div class="introduce">
      <div class="title">邀请奖励</div>
      <div class="content">
        凡您通过邀请链接成功邀请一位好友通过易享家平台预定任意房源或租房服务，则您和好友各可获得200元现金奖励，邀请上限不封顶。同时，您的好友还可获得易享家服务折扣券若干，详情请查看邀请页面。
      </div>
    </div>
    <div class="introduce">
      <div class="title">邀请方式</div>
      <div class="content">
        <div class="invite-text">
          <div class="invite-text-item">
            请您通过此页面，点击邀请好友并通过以下任意方式进行邀请：
          </div>
          <div class="invite-text-item">1） 微信分享给好友；</div>
          <div class="invite-text-item">2） 保持并分享活动海报；</div>
          <div class="invite-text-item">
            您的好友需通过链接进行注册并预定房间或选购服务。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInviteFriendsInfo } from "@/api/api";

export default {
  name: "InviteFriends",
  data() {
    return {
      loading: true,
      pageData: {},
    };
  },
  created() {
    //邀请好友
    getInviteFriendsInfo().then((res) => {
      this.pageData = res.data;
      this.loading = false;
    });
  },
  mounted() {},
  methods: {
    toMyInvite(){
        this.$router.push({
          path:'/myInvite',
        })
    },
    toInviteRule() {
      //跳转到原生的规则页面， todo:这里这么做的原因是app端，在h5页面内跳转，无法实现分步返回，这地方需要插件
      enterInviteRule.postMessage({});
      // this.$router.push('/inviteRule');
    },
    toInviteFriend() {
      //调用原生的方法
      enterInviteFriend.postMessage({});
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  padding: 20px;
  padding-top: 0;
}
.rule {
  width: 100%;
  /*position: absolute;*/
  right: 20px;
  /*background-color: red;*/
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
  border: none;
  font-weight: 500;
}
.title1 {
  padding-top: 50px;
  font-size: 23px;
  font-weight: 800;
  color: #333333;
}
.title2 {
  padding-top: 15px;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  font-weight: 500;
}
.image {
  padding-top: 28px;
  width: 100%;
  height: 240px;
}
.introduce {
  margin: 0 0px 20px;
  padding: 20px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px -5px 10px 0px rgba(51, 51, 51, 0.05);
  .title {
    padding-bottom: 8px;
    font-size: 14px;
    color: #333333;
    font-weight: bold;
  }
  .content {
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    text-align: left;
    line-height: 20px;
  }
  .invite-text-item {
    margin-top: 5px;
  }
}

.invite-button {
  margin-top: 20px;
  background-color: #f67f58; /* Green */
  border: none;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  height: 40;
  width: calc(100% - 125px);
  padding: 6px 32px;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
}

/deep/ .van-button {
  margin: 30 62 20 62;
  background-color: #f67f58;
  height: 40px;
  color: #ffffff;
  font-size: 17;
  font-weight: bold;
}
</style>
