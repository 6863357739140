import request from '@/utils/request'

/**
 * 获取平台条款文档详情
 * @param query
 */
export function getArticleInfo(query) {
    return request({
        url: '/index/getArticleInfo',
        method: 'get',
        params: query
    })
}

/**
 * 文章资讯详情
 * @param query
 */
export function newDetail(query) {
    return request({
        url: '/news/detail',
        method: 'get',
        params: query
    })
}

/**
 * 活动详情
 * @param query
 */
export function getActivityInfo(query) {
    return request({
        url: '/index/getActivityInfo',
        method: 'get',
        params: query
    })
}

/**
 * 获取活动详情页面房源列表
 * @param query
 */
export function getHouseListByIds(query) {
    return request({
        url: '/index/getHouseListByIds',
        method: 'get',
        params: query
    })
}

/**
 * 根据活动获取base64图片
 * @param query
 */
export function getActivityInfoBase64(query) {
    return request({
        url: '/index/convertImgBase64',
        method: 'get',
        params: query
    })
}



/**
 * 房间介绍
 * @param query
 */
export function getRoomintroduceInfo(query) {
    return request({
        url: '/house/getRoomTypeDesc',
        method: 'get',
        params: query
    })
}

/**
 * 服务说明
 * @param query
 */
export function getServicesExplainInfo(query) {
    return request({
        url: '/order/product/getServiceDesc',
        method: 'get',
        params: query
    })
}

/**
 * 邀请好友
 * @param query
 */
export function getInviteFriendsInfo() {
    return request({
        url: '/user/invite/getInvite',
        method: 'get',
        params: {}
    })
}

export function addMessage(data) {
    return request({
        url: '/workMessage/addMessage',
        method: 'post',
        data: data
    })
}


//获取常见问题列表
export function getFaqList(params) {
    return request({
        url: '/user/faq/getFaqList',
        method: 'get',
        params: params
    })
}

//获取常见问题详情
export function getFaqAnswer(params) {
    return request({
        url: '/user/faq/getFaqAnswer',
        method: 'get',
        params: params
    })
}


//获取 平台通知
export function getNotice(params) {
    return request({
        url: '/notice/info',
        method: 'get',
        params: params
    })
}


//获取房源关联拼团活动信息（下单开团展示拼团信息）
export function getGroupActivityInfo(params) {
    return request({
        url: '/groupbuy/activity/content',
        method: 'get',
        params: params
    })
}


export function wechatCommunityDetail(params) {
    return request({
        url: '/index/wechatCommunityDetail',
        method: 'get',
        params: params
    })
}

//根据id查看关系聚合品牌，产品介绍
export function getIntroduceFileById(params) {
    return request({
        url: '/introduce/file/getIntroduceFileById',
        method: 'get',
        params: params
    })
}

//修改小程序链接
export function editIntroduceFile(data) {
    return request({
        url: '/introduce/file/editIntroduceFile',
        method: 'post',
        data
    })
}






